<template>
  <div class="shopOpen">
    <div class="top">注册成为Shopee卖家</div>
    <div class="main-card">
      <div class="form" v-if="neet == 1">
        <el-form ref="form" :model="form" label-width="140px" style="width: 400px; margin: 0 auto">
          <el-form-item label="用户名">
            <el-input v-model="form.username" onfocus="this.removeAttribute('readonly');" readonly>
              <template slot="append">.my</template></el-input>
          </el-form-item>
          <el-form-item label="密码">
            <el-input show-password v-model="form.password" placeholder="密码长度8-16位"></el-input>
          </el-form-item>
          <el-form-item label="确认密码">
            <el-input show-password v-model="form.repassword" placeholder="重新输入密码"></el-input>
          </el-form-item>
          <el-form-item label="电话号码">
            <el-input v-model="form.mobile" placeholder="可以用于登入"></el-input>
          </el-form-item>
          <el-form-item label="短信验证码">
            <el-input v-model="form.code" style="width: 60%" placeholder=" 验证码"></el-input>
            <el-button :disabled="codeType" style="margin-left: 10px; width: 36%" plain @click="getCode">{{ codeText
            }}</el-button>
          </el-form-item>
          <el-form-item style="margin-top: 100px">
            <el-button type="primary" @click="onSubmit" style="width: 175px; height: 42px">提交</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="chengong" v-if="neet == 2">
        <i class="el-icon-document-checked" style="font-size: 140px; color: #59c3af"></i>
        <div class="text">注册成功</div>
        <div style="font-szie: 18px; margin-top: 20px">
          <span style="color: #d0d0da">您的用户名是</span>
          {{ form.username }}，马上登录
          <span style="color: #41a4f4" @click="toShopLogin" class="tologin">
            虾皮卖家中心<i class="el-icon-arrow-right" style="font-size: 16px"></i></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { open } from "@/api/shop.js";
import { getSmsCode } from "@/api/common.js";
export default {
  data() {
    return {
      neet: 1,
      form: {
        username: "",
        password: "",
        repassword: "",
        mobile: "",
        code: "",
      },
      codeNum: 60,
      codeText: "发送",
      codeType: false,
      codeTime: null,
    };
  },
  created() {
    if (sessionStorage.MAIN_PHONE) {
      this.form.mobile = sessionStorage.MAIN_PHONE
    }
  },
  methods: {
    onSubmit() {
      let obj = JSON.stringify(this.form);
      obj = JSON.parse(obj);
      obj.username = obj.username + '.my'
      open(obj).then((res) => {
        if (res.code == 1) {
          this.neet = 2;
        }
      });
    },
    getCode() {
      let _this = this;
      getSmsCode({
        mobile: this.form.mobile,
      }).then((res) => {

        if (res.code == 1) {
          _this.codeType = true;
          _this.$message.success(res.message);
          setTimeout(function () {
            _this.form.code = res.data.code;
          }, 1500);
          this.codeTime = setInterval(function () {
            _this.codeNum--;
            _this.codeText = _this.codeNum + "s";
            if (_this.codeNum == 0) {
              clearInterval(_this.codeTime);
              _this.codeNum = 60;
              _this.codeText = "发送";
              _this.codeType = false;
            }
          }, 1000);
        } else {
          _this.codeType = false;
        }
      });
    },
    toShopLogin() {
      this.$router.replace("shopCenter");
    },
  },
};
</script>
<style lang="less" scoped>
.shopOpen {
  .top {
    background: #fff4ec;
    color: #ee5740;
    font-size: 24px;
    text-align: center;
    width: 100%;
    height: 190px;
    line-height: 190px;
  }

  .form {
    padding: 60px 160px 0 60px;
  }

  .chengong {
    text-align: center;
    padding: 80px;

    .text {
      font-size: 24px;
      color: #59c3af;
      margin-top: 10px;
    }

    .tologin {
      cursor: pointer;
    }
  }
}
</style>